.PortableText p:first-child {
  padding-top: 0px;
}

.PortableText p {
  padding-top: 13px;
}

a:hover {
  cursor: pointer !important;
}

.Hover:hover {
  cursor: pointer!important;
}

.Hover:hover .ContextMenu:hover {
  cursor: context-menu!important;
}

.Disabled:hover {
  cursor: not-allowed !important;
}

.HoverText {
  cursor: text!important;
}

.DisableSelection,
.DisableSelection > * {
  user-select: none!important;
  -ms-user-select: none!important;
  -moz-user-select: none!important;
  -khtml-user-select: none!important;
  -webkit-user-select: none!important;
  -webkit-touch-callout: none!important;
}

/* Loader Spinner */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Blur Background workaround */
.Blur {
  background: rgba(255, 255, 255, 0.993);
}

@supports (backdrop-filter: blur(30px)) {
  .Blur {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}

/* Charging Flow - Charging Animation */

.ChargingFlow_Charging_0_slow {
  animation: ChargingFlow0 1.2s linear infinite;
  -webkit-animation: ChargingFlow0 1.2s linear infinite;
}
.ChargingFlow_Charging_0_medium {
  animation: ChargingFlow0 1s linear infinite;
  -webkit-animation: ChargingFlow0 1s linear infinite;
}
.ChargingFlow_Charging_0_fast {
  animation: ChargingFlow0 0.8s linear infinite;
  -webkit-animation: ChargingFlow0 0.8s linear infinite;
}
.ChargingFlow_Charging_0_faster {
  animation: ChargingFlow0 0.4s linear infinite;
  -webkit-animation: ChargingFlow0 0.4s linear infinite;
}
.ChargingFlow_Charging_0_fastest {
  animation: ChargingFlow0 0.2s linear infinite;
  -webkit-animation: ChargingFlow0 0.2s linear infinite;
}

@keyframes ChargingFlow0 {
  from { stroke-dashoffset: -311.11; }
  to { stroke-dashoffset: 0; }
}
@-webkit-keyframes ChargingFlow0 {
  from { stroke-dashoffset: -311.11; }
  to { stroke-dashoffset: 0; }
}

.ChargingFlow_Charging_1_slow {
  animation: ChargingFlow1 1.2s linear infinite;
  -webkit-animation: ChargingFlow1 1.2s linear infinite;
}
.ChargingFlow_Charging_1_medium {
  animation: ChargingFlow1 1s linear infinite;
  -webkit-animation: ChargingFlow1 1s linear infinite;
}
.ChargingFlow_Charging_1_fast {
  animation: ChargingFlow1 0.8s linear infinite;
  -webkit-animation: ChargingFlow1 0.8s linear infinite;
}
.ChargingFlow_Charging_1_faster {
  animation: ChargingFlow1 0.4s linear infinite;
  -webkit-animation: ChargingFlow1 0.4s linear infinite;
}
.ChargingFlow_Charging_1_fastest {
  animation: ChargingFlow1 0.2s linear infinite;
  -webkit-animation: ChargingFlow1 0.2s linear infinite;
}

@keyframes ChargingFlow1 {
  from { stroke-dashoffset: 0; }
  to { stroke-dashoffset: -311.11; }
}
@-webkit-keyframes ChargingFlow1 {
  from { stroke-dashoffset: 0; }
  to { stroke-dashoffset: -311.11; }
}

/* Fade in animations */
.FadeIn {
  animation: FadeIn .2s ease-in 0s forwards;
  opacity: 0;
}

@keyframes FadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Reset background animation */
@keyframes ResetBackground {
  from { background-color: #F5F5F5; }
  to { background-color: transparent; }
}

/* Text dots animation */
@keyframes dot1 { from { opacity: 0; } 25% { opacity: 1; } }
@keyframes dot2 { from { opacity: 0; } 50% { opacity: 1; } }
@keyframes dot3 { from { opacity: 0; } 75% { opacity: 1; } }
@-webkit-keyframes dot1 { from { opacity: 0; } 25% { opacity: 1; } }
@-webkit-keyframes dot2 { from { opacity: 0; } 50% { opacity: 1; } }
@-webkit-keyframes dot3 { from { opacity: 0; } 75% { opacity: 1; } }


.TextDots span {
  animation: dot1 1s infinite steps(1);
  -webkit-animation: dot1 1s infinite steps(1);
}

.TextDots span:first-child + span {
    animation-name: dot2;
    -webkit-animation-name: dot2;
}

.TextDots span:first-child + span + span {
    animation-name: dot3;
    -webkit-animation-name: dot3;
}
