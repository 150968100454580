@keyframes dot1 { from { opacity: 0; } 25% { opacity: 1; } }
@keyframes dot2 { from { opacity: 0; } 50% { opacity: 1; } }
@keyframes dot3 { from { opacity: 0; } 75% { opacity: 1; } }
@-webkit-keyframes dot1 { from { opacity: 0; } 25% { opacity: 1; } }
@-webkit-keyframes dot2 { from { opacity: 0; } 50% { opacity: 1; } }
@-webkit-keyframes dot3 { from { opacity: 0; } 75% { opacity: 1; } }


.ErrorBar_Text span {
  animation: dot1 1s infinite steps(1);
  -webkit-animation: dot1 1s infinite steps(1);
}

.ErrorBar_Text span:first-child + span {
    animation-name: dot2;
    -webkit-animation-name: dot2;
}

.ErrorBar_Text span:first-child + span + span {
    animation-name: dot3;
    -webkit-animation-name: dot3;
}
