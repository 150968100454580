#hs-eu-cookie-confirmation {
  background-color: #FFFFFF!important;
  width: 350px!important;
  bottom: 15px!important;
  left: 15px!important;
  padding: 10px!important;
  margin: 0px!important;
  border: solid 0px transparent!important;
  border-radius: 10px!important;
  height: auto!important;
  box-shadow: rgba(51, 51, 51, 0.25) 0px 3px 15px 2px!important;
}
#hs-eu-cookie-confirmation-inner {
  padding: 0px!important;
}
#hs-eu-close-button-container {
   margin-top: 0px!important;
   margin-right: 0px!important;
}
#hs-eu-policy-wording, #hs-eu-policy-wording p {
  margin-top: 5px!important;
  font-family: 'Museo-Sans'!important;
  font-weight: 300!important;
  font-size: 1.15em!important;
  line-height: 20px!important;
  padding: 0px!important;
  margin: 0px!important;
}
#hs-eu-cookie-disclaimer {
  margin-left: 0px!important;
  margin-right: 0px!important;
  margin-left: 0px!important;
  margin-top: 10px!important;
  font-family: 'Museo-Sans'!important;
  font-size: 1.25em!important;
  line-height: 20px!important;
}
#hs-eu-cookie-confirmation-inner #hs-eu-policy-wording p {
  font-weight: 300!important;
}
#hs-en-cookie-confirmation-buttons-area {
  margin-top: 30px!important;
  width: 100%!important;
}
#hs-eu-cookie-confirmation-button-group {
  width: 100%!important;
  justify-content: flex-start!important;
  padding: 0px!important;
}
#hs-eu-cookie-confirmation-button-group a {
  margin: 0px!important;
  width: 100%!important;
  border-radius: 10px!important;
  min-height: 50px!important;
  display: flex!important;
  flex-direction: row!important;
  align-items: center!important;
  justify-content: center!important;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  font-family: 'Museo-Sans'!important;
}
#hs-eu-cookie-disclaimer {
  font-size: 1.3em!important;
  font-family: 'Museo-Sans'!important;
}

@media (max-width: 576px) {
  #hs-eu-cookie-confirmation {
    bottom: 0px!important;
    left: 0px!important;
    border-radius: 0px!important;
    width: 100%!important;
  }
  #hs-eu-cookie-confirmation-inner {
    padding: 0px!important;
  }
}

/* Live Chat Widget */
#hubspot-conversations-inline-parent {
  height: 100%;
  width: 100%;
}

#hubspot-conversations-inline-iframe {
  height: 100%;
  width: 100%;
  border: solid 2px var(--colors-MEDIUMWHITE);
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px var(--colors-MEDIUMGREY);
}
