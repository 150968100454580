.react-calendar {
  width: 100%;
  max-width: 100%;
  line-height: 1.125em;
  padding: 0px;
  margin: 0px;
}

/* Navigation */
.react-calendar__navigation {
  height: 45px;
  display: flex;
  flex-direction: row;
}

.react-calendar__navigation__arrow {
  min-width: 45px;
  font-size: 1.1em;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--colors-WHITE);
  color: var(--colors-GREY);
}

.react-calendar__navigation__arrow:hover {
  cursor: pointer;
  background-color: var(--colors-LIGHTGREY);
}

.react-calendar__navigation__prev2-button {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border: solid 2px var(--colors-MEDIUMWHITE);
}

.react-calendar__navigation__prev-button {
  border-top: solid 2px var(--colors-MEDIUMWHITE);
  border-left: solid 0px transparent;
  border-bottom: solid 2px var(--colors-MEDIUMWHITE);
  border-right: solid 0px transparent;
}

.react-calendar__navigation__next-button {
  border-top: solid 2px var(--colors-MEDIUMWHITE);
  border-left: solid 0px transparent;
  border-bottom: solid 2px var(--colors-MEDIUMWHITE);
  border-right: solid 0px transparent;
}

.react-calendar__navigation__next2-button {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border: solid 2px var(--colors-MEDIUMWHITE);
}

.react-calendar__navigation__label {
  border: solid 2px var(--colors-MEDIUMWHITE);
  background-color: var(--colors-WHITE);
  font-size: 1em;
  color: var(--colors-GREY);
  height: 100%;
  margin: 0px;
}

.react-calendar__navigation__label:hover {
  cursor: pointer;
  background-color: var(--colors-LIGHTGREY);
}

/* Month view */
.react-calendar__viewContainer {
  margin-top: 10px;
}

/* Weekdays */
.react-calendar abbr {
  text-decoration: none;
  color: var(--colors-GREY);
}

.react-calendar__month-view__weekdays {
  margin-bottom: 5px;
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: capitalize;
  font-size: 0.8em;
  font-weight: 100;
}

.react-calendar__month-view__weekdays__weekday abbr {
  color: var(--colors-DARKGREY);
}

.react-calendar__month-view__weekdays__weekday:hover {
  cursor: default;
  user-select: none!important;
  -ms-user-select: none!important;
  -moz-user-select: none!important;
  -khtml-user-select: none!important;
  -webkit-user-select: none!important;
  -webkit-touch-callout: none!important;
}

/* Days */
.react-calendar__month-view__days {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

/* Tiles */
.react-calendar__tile {
  min-height: 45px;
  width: 50px;
  background-color: transparent;
  border: solid 0px transparent;
  font-size: 1.1em;
  font-weight: 300;
  padding: 0px;
}

.react-calendar__tile:enabled:hover {
  border: solid 2px var(--colors-MEDIUMGREY);
  border-radius: 10px;
  cursor: pointer;
}

.react-calendar__tile:disabled, .react-calendar__tile:disabled abbr {
  color: var(--colors-MEDIUMWHITE);
}

.react-calendar__tile--now {
  background-color: var(--colors-LIGHTWHITE);
  border-radius: 10px;
}

.react-calendar__tile--active {
  background-color: var(--colors-YELLOW);
  border-radius: 10px;
}
.react-calendar__tile--active:hover {
  border: solid 2px var(--colors-YELLOW)!important;
  cursor: default!important;
}
